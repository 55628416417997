@use "variables" as var;

// @VuOnCss
// 1. I wish there was a css library that helps
// me generate all these utility classes
// 2. And I wish people in my teams actually use
// these classes rather than defining their own
// weird classes, which they themselves never reuse
// anyway.
$primary-color: var.$primary-blue;
$success-color: var.$secondary-green;
$danger-color: var.$secondary-red;
$secondary-color: var.$light-gray;
$whitesmoke-color: var.$unack-noti-bg;
$white-color: var.$white;
$black-color: var.$black;
$colors: (
  "primary": $primary-color,
  "success": $success-color,
  "danger": $danger-color,
  "secondary": $secondary-color,
  "whitesmoke": $whitesmoke-color,
  "black": $black-color,
  "white": $white-color,
);
// @VuOnCss
// Leave these here because I don't know where to leave them
.secondary-green {
  color: var.$secondary-green;
}
.secondary-red {
  color: var.$secondary-red;
}
.primary-blue {
  color: var.$primary-blue;
}
@each $type, $color in $colors {
  .btn-#{$type} {
    background-color: $color !important;
    color: white !important;
  }
  .btn-outline-#{$type} {
    border-color: $color !important;
    border-width: 1px !important;
    color: $color !important;
    background-color: transparent;
  }
  .text-#{$type} {
    color: $color !important;
  }
  .text-#{$type} > .mat-progress-spinner circle,
  .text-#{$type} > .mat-spinner circle {
    stroke: $color;
  }
  .bg-#{$type} {
    background-color: $color !important;
  }
}
.w-100 {
  width: 100%;
}
.w-95 {
  width: 95%;
}
.w-80 {
  width: 80%;
}
.w-60 {
  width: 60%;
}

.min-width {
  min-width: 168px;
}
.max-width {
  max-width: 168px;
}

.font-weight-bold {
  font-weight: bold;
}
.text-center {
  text-align: center;
}
.text-underline {
  text-decoration: underline;
}
.text-right {
  text-align: right;
}
.text-sm {
  font-size: smaller;
}
.text-sm .mat-icon {
  font-size: smaller !important;
  width: 16px;
  height: 16px;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-initial {
  font-size: var.$font-size-base !important;
}
small {
  font-size: smaller;
}
.c-pointer {
  cursor: pointer;
}
.d-flex {
  display: flex;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.d-flex .flex-grow {
  flex-grow: 1;
}
.d-flex .item-width-equal {
  flex: 1 1 0;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.align-items-stretch {
  align-items: stretch;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: end;
}
.btn {
  padding: 0.65rem 2em;
  text-decoration: none;
  cursor: pointer;
  border-width: 0px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn .material-icons {
  font-size: var.$font-size-base !important;
  height: auto !important;
}
.btn.btn-pill {
  border-radius: 1.5em;
}
.btn:hover {
  box-shadow: 0px 1px 8px #0f2b7966;
}
.btn-sm {
  padding: 0.5rem 1em !important;
}
.btn-plain {
  background-color: transparent;
}
.btn-plain:hover {
  box-shadow: none;
  text-decoration: underline;
}
.btn-sm.btn-pill {
  border-radius: 90px !important;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
$spacing-1: 0.5rem;
@for $i from 0 through 5 {
  .p-#{$i} {
    padding: ($spacing-1 * $i);
  }
  .pt-#{$i} {
    padding-top: ($spacing-1 * $i);
  }
  .pr-#{$i} {
    padding-right: ($spacing-1 * $i);
  }
  .pb-#{$i} {
    padding-bottom: ($spacing-1 * $i);
  }
  .pl-#{$i} {
    padding-left: ($spacing-1 * $i);
  }
  .py-#{$i} {
    padding-top: ($spacing-1 * $i) !important;
    padding-bottom: ($spacing-1 * $i) !important;
  }
  .px-#{$i} {
    padding-left: ($spacing-1 * $i) !important;
    padding-right: ($spacing-1 * $i) !important;
  }
  .m-#{$i} {
    margin: ($spacing-1 * $i);
  }
  .mt-#{$i} {
    margin-top: ($spacing-1 * $i);
  }
  .mr-#{$i} {
    margin-right: ($spacing-1 * $i);
  }
  .ml-#{$i} {
    margin-left: ($spacing-1 * $i);
  }
  .mb-#{$i} {
    margin-bottom: ($spacing-1 * $i);
  }
  .mx-#{$i} {
    margin-right: ($spacing-1 * $i);
    margin-left: ($spacing-1 * $i);
  }
  .my-#{$i} {
    margin-top: ($spacing-1 * $i);
    margin-bottom: ($spacing-1 * $i);
  }
}

// @VuOnCss why is this here???
// didn't we already have a p-0 class above?
.no-padding {
  padding: 0;
}

// @VuOnCss custom bootstrap container and column
// I wonder if there is a library that helps me
// do this already???
.bootstrap-wrapper {
  .container-fluid {
    box-sizing: border-box;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .row-gap {
    row-gap: 1rem;
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.gap-3px {
  gap: 3px;
}
