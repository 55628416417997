@use "variables" as var;

.mat-tooltip.notification-tooltip {
  padding: 8px;
  background-color: var.$primary-blue;
  color: var.$white;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-left: 60px;
  margin-top: 8px;
}

.custom-mat-tooltip {
  background-color: var.$primary-blue;
}
