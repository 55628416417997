@use "variables" as var;

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background: var.$primary-blue;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background: var.$primary-blue-light;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background: var.$primary-blue;
}
