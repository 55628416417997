// CSS selectors for some custom modals. Can consider removal
@use "variables" as var;

.hc-preview-modal {
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
  background: rgba(46, 50, 69, 0.7);
  font-family: var.$font-family-base;
  height: 100vh;
  overflow-y: hidden;
  .hc-modal-container {
    position: relative;
    background: #ffffff;
    box-shadow: -3px 0px 20px #0f2b7926;
    border-radius: 15px;
    margin-top: 5%;
    width: 72%;
    margin-left: 12%;
    text-align: left;
    &--content {
      height: 52vh;
      overflow-y: scroll;
      padding: 50px 30px 0 30px;
      margin-bottom: 1px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .title-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d6d6d6;
    .modal-title {
      font-size: var.$font-size-medium;
      padding: 0 0 15px 0;
      margin: 0;
      font-family: var.$font-family-base;
    }
  }
  .modal-content {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    font-size: var.$font-size-small;
    padding-top: 15px;
    // border-bottom: 1px solid #e1e1e1;
    h4 {
      padding: 0;
      margin: 0;
    }
    .title {
      padding: 8px 0;
      color: var.$black;

      .optional {
        font-style: italic;
      }
    }
    .content {
      color: var.$black;
      font-weight: bold;
    }
    .modal-content-container {
      padding: 0 0 15px 0;
    }
  }
  .remark-container {
    div {
      padding: 0;
    }
  }
  // WK currently will only affect .hc-preview-modal may want to make it generic to be reusable
  .button-container {
    margin-top: 0%;
    text-align: left;
    // WK, not sure why this is needed when padding changed
    width: 72%;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .left-button-container {
      > * {
        margin-right: 15px;
      }
    }
    .right-button-container {
      display: flex;
      align-items: center;
      > * {
        margin-left: 15px;
      }
      .left-icon {
        margin-left: 0px;
        padding-left: 5px;
      }
    }
  }
  // LINK TITLE
  .title-back {
    color: var.$primary-blue;
    text-align: right;
    font-size: var.$font-size-small;
  }
}

// WK: this looks like a hack to override the preview modal
// preview modal component currently is in trade full table component
.history-modal {
  color: var.$gray;
  .hc-modal-container {
    padding: 50px 60px !important;
    width: 85% !important;
    text-align: left;
  }
  h2 {
    font-size: var.$font-size-medium;
    color: var.$black;
    text-align: left;
    padding-bottom: 15px;
    margin: 0;
  }
  .title {
    border-bottom: 1px solid #eee;
  }
  .title-back {
    color: var.$primary-blue;
    text-align: right;
  }
  // QUOTATION HISTORY GRID
  .hc-grid-header {
    grid-template-columns: repeat(11, 1fr) !important;
    column-gap: 15px;
    padding: 15px 0px !important;
    .hc-grid {
      display: grid;
      border-left: none !important;
      border-right: none !important;
    }
    .hc-grid-item {
      padding: 0 !important;
      color: var.$black !important;
    }
    border-radius: 0 !important;
    border-bottom: 2px solid var.$primary-blue-light !important;
    border-top: 2px solid var.$primary-blue-light !important;
    border-left: none !important;
    border-right: none !important;
    text-align: left;
  }
  .hc-grid-data {
    padding-top: 15px;
    .hc-grid {
      grid-template-columns: repeat(11, 1fr) !important;
      padding-top: 15px;
      padding-bottom: 15px;
      column-gap: 15px;
      text-align: left;
      border: none !important;
    }
    .hc-grid-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 !important;
    }
  }
}
