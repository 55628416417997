// Unfortunately, all these style will now
// be obsolete because of new Price Widgets and Display Components
// Marked for deletion
@use "variables" as var;

@mixin arrow-down-style {
  margin-right: 10px;
  border: solid var.$black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg) translate3d(-4px, 0, 0);
}

// Customized mat-select for sicom-header filters
.sicom-header-select {
  #hc-dropdown {
    padding-top: 0;
    padding-bottom: 0;
    background-color: var.$secondary-blue-light;
    border-radius: 30px;
    line-height: 40px;
    width: auto;

    .mat-select-arrow {
      @include arrow-down-style();
    }

    .hc-select-trigger {
      font-size: 14px;
      line-height: 28px;
      display: flex;
      height: 28px;

      span:nth-child(1) {
        font-weight: 600;
        color: var.$gray;
      }

      span:nth-child(2) {
        font-weight: 600;
        color: var.$black;
        margin-left: 5px;
      }
    }

    .mat-select {
      width: 163px;
      border: unset;
      text-align: center;
      vertical-align: top;
      padding: 0;
      padding-left: 14px;
    }
  }

  #hc-dropdown:last-child {
    margin-right: 0;
  }
}

.sicom-header-bottom-sheet {
  display: none;

  .custom-select-button:first-child {
    margin-right: 7px;
  }

  .custom-select-button {
    position: relative;
    background-color: var.$secondary-blue-light;
    border-radius: 46px;
    width: 100%;
    text-align: initial;
    height: 40px;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    font-family: var.$font-family-base;
    font-weight: 400;
    font-size: 14px;
    padding: 0 24px;
  }

  .button-value {
    width: 100%;
  }

  .button-arrow {
    @include arrow-down-style();
    margin-right: 0;
  }

  .hidden-block {
    opacity: 0;
  }
}

@media only screen and (max-width: var.$breakpoint-design-md) {
  .filters {
    #hc-dropdown {
      .mat-select-arrow-wrapper {
        padding-left: 14px;
      }

      .mat-form-field-infix {
        max-width: max-content;
      }

      .mat-select {
        width: 100%;
      }
    }
  }
}
