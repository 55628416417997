// Various container classes in the app
// Definitely consider removing
@use "variables" as var;

.hc-panel-filter {
  background: var.$white 0% 0% no-repeat padding-box;
  box-shadow: var.$white-theme-box-shadow-color;
  border-radius: 15px;
  padding: 30px 15px;
  #hc-dropdown {
    margin-right: 32.5px;
  }
  #hc-dropdown:first-child {
    margin-left: 0;
  }
  .first-row {
    border-bottom: 1px solid var.$light-gray;
  }
  .third-row {
    padding: 20px 30px 10px 30px;
    border-top: 1px solid #d6d6d6;
    .show-right {
      float: right;
    }
  }
  // Button
  #hc-button-primary,
  #hc-button-decline {
    margin-right: 15px;
    font-size: var.$font-size-small;
  }
  #hc-button-basic {
    margin-right: 15px;
    font-size: var.$font-size-small;
    padding: 0px 40px;
    margin-top: -2px;
  }
}

.hc-saved-item-container {
  display: flex;
  padding: 20px 30px;
  justify-content: space-between;
  background: var.$white 0% 0% no-repeat padding-box;
  box-shadow: var.$white-theme-box-shadow-color;
  border-radius: 15px;
  margin-top: 30px;
  .title {
    color: var.$primary-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var.$font-size-small;
  }
  #hc-button-primary,
  #hc-button-accept,
  #hc-button-decline {
    margin-right: 15px;
    font-size: var.$font-size-small;
  }
}

.no-result-found {
  margin: 0 auto;
  text-align: center;
  padding: 60px 0;
  .table-result {
    font-size: var.$font-size-medium;
    color: var.$black;
    margin: 0;
    padding: 11px;
  }
  .text {
    font-size: var.$font-size-small;
    margin: 0;
    padding: 0;
    color: var.$dark-gray;
  }
  .title {
    font-size: var.$font-size-medium;
    margin: 0;
    padding-bottom: 11px;
  }
}

.remark-container {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
  padding-right: 30px;
  .hc-textarea {
    width: 100%;
  }
  div {
    padding: 0 20px;
  }
}

.hc-price-container {
  border-top: 1px solid #e1e1e1;
  h5 {
    font-family: var.$font-family-base;
    font-size: var.$font-size-small;
    margin: 0;
    padding: 0;
  }
  .hc-price-header {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    border-left: 5px solid var.$primary-blue-light;
    border-bottom: 1px solid #d6d6d6;
    .hc-price-header-title {
      display: flex;
      justify-content: space-between;
      width: 50%;
      font-family: var.$font-family-base;
      font-weight: 600;
    }
  }
  .hc-price-body {
    border-left: 5px solid var.$primary-blue-light;
    .hc-price-body-content {
      width: 50%;
      padding-bottom: 30px;
      padding-top: 19px;
    }
    .hc-price-body-content-row {
      font-weight: 600;
      font-family: var.$font-family-base;
      display: flex;
      justify-content: space-between;
      padding: 11px 30px;
    }
    .floor-price-content {
      padding: 2px 10px;
      min-width: 60px;
      height: 20px;
    }
  }
  #hc-button-primary {
    margin-right: 15px;
    font-size: var.$font-size-small;
  }
}

.hc-tabs-container {
  display: flex;
  justify-content: space-between;
  font-size: var.$font-size-small;
  padding: 30px 0;
  .hc-tabs {
    width: 100%;
    .mat-tab-label-content {
      font-size: var.$font-size-title-text;
      color: var.$gray;
      font-family: var.$font-family-base;
    }
    .mat-tab-label-active {
      .mat-tab-label-content {
        color: var.$black;
      }
    }
    .mat-tab-label {
      min-width: 120px;
    }
  }
}
