// VU-20230916: the styles in this file are
// very outdated classes that are used
// in unmaintained legacy components.
// We should get rid of these as soon as we can
@use "variables" as var;

.hc-title-text {
  font-size: var.$font-size-title-text;
  font-family: var.$font-family-base;
}

.hc-link-title {
  color: var.$primary-blue;
  font-weight: bold;
  cursor: pointer;
}

.hc-title-18 {
  font-size: var.$font-size-medium;
  font-family: var.$font-family-base;
  font-weight: bold;
}

.second-label {
  color: var.$gray;
  font-size: var.$font-size-small;
}

.sort-content {
  color: var.$dark-gray;
  font-size: var.$font-size-small;
}
