@use "variables" as var;

// Currently used in chat-related components
.expand-icon {
  background: url("/../../../assets/images/down.svg") no-repeat;
  display: inline-block;
  width: 8px;
  background-size: 8px;
  height: 8px;
}

// Only used in split-contract component
.close-icon {
  background: url("/../../../assets/images/up.svg") no-repeat;
  display: inline-block;
  width: 15px;
  background-size: 8px;
  height: 8px;
}

// Only used in contract-table component
.download-icon {
  cursor: pointer;
}

// Only used in contract-table component
.close-blue-icon {
  background: url("/../../../assets/images/blue-arrow-up.svg") no-repeat;
  display: inline-block;
  width: 15px;
  background-size: 10px;
  height: 8px;
  cursor: pointer;
  margin-left: 8px;
}

// Only used in contract-table component
.expand-blue-icon {
  background: url("/../../../assets/images/blue-arrow-down.svg") no-repeat;
  display: inline-block;
  width: 15px;
  background-size: 10px;
  height: 8px;
  cursor: pointer;
  margin-left: 8px;
}

// Only used in chat-timeline-calendar component
.left-icon {
  background: url(/../../../assets/images/arrows.svg) no-repeat;
  display: inline-block;
  width: 23px;
  background-size: 90px;
  height: 13px;
  background-position: -75px;
  cursor: pointer;
}

// only used in revise-details-modal component
.icon-right {
  background: url("/../../..//assets/images/arrows.svg") no-repeat;
  background-size: 150px;
  width: 9px;
  height: 18px;
  display: inline-block;
  background-position: -93px 0px;
  cursor: pointer;
  padding-left: 18px;
  border: none;
}

// Used in chat-related components
.right-icon {
  background: url(/../../../assets/images/arrows.svg) no-repeat;
  display: inline-block;
  width: 23px;
  background-size: 90px;
  height: 13px;
  background-position: -24px;
  /* border: 1px solid red; */
}

// Used in gpsnr-form, top-menu, profile-detail components
.icon-profile {
  border-radius: 50%;
  width: 55px;
  height: 35px;
  padding: 0 10px;
  background-position: center;
}

// Used in chat-related components
.icon-user-chat {
  background: url(/../../../assets/images/user-chat.svg) no-repeat;
  display: inline-block;
  width: 33px;
  background-size: 33px;
  height: 35px;
  background-position: center;
}

// Used in chat-related components
.icon-chat-active-dot {
  background: url(/../../../assets/images/chat-active-dot.svg) no-repeat;
  display: inline-block;
  width: 5px;
  background-size: 5px;
  height: 5px;
  background-position: center;
}

// Used by chat component
.icon-chat-discuss-inactive {
  background: url(/../../../assets/images/chat-discuss-inactive.svg) no-repeat;
  display: inline-block;
  width: 18px;
  background-size: 18px;
  height: 18px;
  background-position: center;
}

// Used by chat component
.icon-chat-discuss-inactive:hover {
  background: url(/../../../assets/images/chat-discuss-hover.svg) no-repeat;
  display: inline-block;
  width: 18px;
  background-size: 18px;
  height: 18px;
  background-position: center;
}

// Used by chat component
.icon-chat-favorites-inactive {
  background: url(/../../../assets/images/chat-favorites-inactive.svg) no-repeat;
  display: inline-block;
  width: 18px;
  background-size: 18px;
  height: 18px;
  background-position: center;
}

// Used by chat component
.icon-chat-favorites-inactive:hover {
  background: url(/../../../assets/images/chat-favorites-hover.svg) no-repeat;
  display: inline-block;
  width: 18px;
  background-size: 18px;
  height: 18px;
  background-position: center;
}

// Used by chat component
.icon-chat-favorites-active {
  background: url(/../../../assets/images/chat-favorites-active.svg) no-repeat;
  display: inline-block;
  width: 18px;
  background-size: 18px;
  height: 18px;
  background-position: center;
}

// Used by chat component
.icon-chat-settings-inactive {
  background: url(/../../../assets/images/chat-settings-inactive.svg) no-repeat;
  display: inline-block;
  width: 18px;
  background-size: 18px;
  height: 18px;
  background-position: center;
}

// Used by chat component
.icon-chat-settings-inactive:hover {
  background: url(/../../../assets/images/chat-settings-hover.svg) no-repeat;
  display: inline-block;
  width: 18px;
  background-size: 18px;
  height: 18px;
  background-position: center;
}

// Used by chat component
.icon-chat-settings-active {
  background: url(/../../../assets/images/chat-settings-active.svg) no-repeat;
  display: inline-block;
  width: 18px;
  background-size: 18px;
  height: 18px;
  background-position: center;
}

// Used by chat component
.icon-three-dots {
  background: url(/../../../assets/images/three-dot.svg) no-repeat;
  display: inline-block;
  width: 20px;
  background-size: 3px;
  height: 20px;
  background-position: center;
}

// Used by chat component
.close-right-icon {
  background: url("/../../../assets/images/right.svg") no-repeat;
  display: inline-block;
  width: 15px;
  background-size: 4px;
  height: 8px;
}

.remark-empty {
  color: var.$light-gray;
}

.remark-filled {
  color: var.$primary-blue;
}
