/*Font Family */
$font-family-base: "Nunito Sans";
$font-size-base: 14px;

/*Font Size */
$font-size-xxsmall: 10px; //tooltip
$font-size-xsmall: 12px; //tooltip
$font-size-small: 14px;
$font-size-title-text: 16px;
$font-size-medium: 18px;
$font-size-large: 21px;
$font-size-xlarge: 28px;

/* Color */
$header-text: var(--headerText); // #FFF;
$header-bg: var(--headerBackground); // $primary-blue: #1446ce;
$primary-blue: var(--primaryColor); // $primary-blue: #1446ce;
$primary-button-text-color: var(--primaryBtnTextColor);

$table-secondary-bg: var(--tableSecondaryBackground); //  #dfe6f8,
$menu-bg: var(--menuBackground);
$menu-text-color: var(--menuTextColor);

$accent-color: var(--accentColor); // $primary-blue

$tabl-primary-bg: var(--tablPrimaryBackground); //

$primary-blue-light: var(--secondaryColor); // #95ace9; //#8aa3e6
$secondary-blue-light: var(--tableSecondaryBackground); // #dfe6f8;

$secondary-red: #ff7b77;
$secondary-red-light: #fac0c0;
$secondary-green: #2d9a36;
$secondary-green-light: #d9e8e0;
$warning: #fbc02d;
$black: #000;
$dark-gray: #404040;
$gray: #595959;
$light-gray: #b9b9b9;
$image-bg-gray: #c4c4c4;
$table-header-gray: #696969;
$table-row-selected: #e6e6e6;
$unack-noti-bg: #f5f6fa;
$white: #ffffff;
$almost-white: #fafafa;
$very-light-shade-gray: #eeeeee;
$light-grayish-blue: #dee6f8;
$light-grayish-yellow: #f3f5ef;
$conti-orange: #ffa500;
$dark-green: #014421;

/* white Theme */
$white-theme-background-gray: #f5f6fa;
$white-theme-main-font-color: #b9b9b9;
$white-theme-left-menu: var(--primaryColor);
$white-theme-left-menu-2: $white;

// TO BE REMOVED SINCE NOT USING //

/* Modal button */

$modal-blue-button-background: linear-gradient(
  270deg,
  $primary-blue-light,
  $primary-blue
);
$modal-red-button-background: linear-gradient(
  270deg,
  #ff7b7780,
  $secondary-red
);
$modal-green-button-background: linear-gradient(
  270deg,
  $secondary-green-light,
  $secondary-green
);
$modal-blue-button-background-hover: linear-gradient(
  103deg,
  $primary-blue-light,
  $primary-blue
);

$modal-red-button-background-hover: linear-gradient(
  103deg,
  #ff7b7780,
  $secondary-red
);
$modal-green-button-background-hover: linear-gradient(
  103deg,
  $secondary-green-light,
  $secondary-green
);
$modal-blue-button-background-active: $primary-blue;
$modal-red-button-background-active: $secondary-red;
$modal-green-button-background-active: $secondary-green;

/* button */
$white-theme-main-button-primary-color: transparent
  linear-gradient(270deg, var(--secondaryColor) 0%, var(--primaryColor) 100%);
$white-theme-main-button-primary-color-disable: transparent
  linear-gradient(103deg, var(--secondaryColor) 0%, var(--primaryColor) 100%);

/* box shadow */
$white-theme-box-shadow-color: 0px 3px 20px #0f2b7912;
$white-theme-box-shadow-color-less-blur: 0px 1px 2px 0px #00000040;

/* Black Theme */
$black-theme-background-black: #1c1b21;
$black-theme-main-font-color: #fff;
$black-theme-left-menu: #121214;

/* button */
$black-theme-main-button-primary-color-disable: #8190e5;
$black-theme-main-button-primary-color: #4971ff;

/* box shadow */
$black-theme-box-shadow: 0px 3px 20px #0f2b7912;

/* Viewport Width */
$breakpoint-mobile: 480px;
$breakpoint-tablet-portrait: 768px;
$breakpoint-tablet-landscape: 1024px;
$breakpoint-desktop: 1280px;

/* 
Breakpoints used in Figma design 
https://www.figma.com/proto/vSrw3zj6z6bHz1AydCeOz4/SICOM-Bar?page-id=2%3A13437&node-id=1330%3A9777&viewport=-998%2C1234%2C0.5&scaling=min-zoom&starting-point-node-id=1330%3A9777&show-proto-sidebar=1
*/
$breakpoint-design-xs: 600px;
$breakpoint-design-sm: 905px;
$breakpoint-design-md: 1240px;

/* Breakpoints from Bootstrap */
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
