// Currently on used by the chat-related components
// Once refactored chat app, we can remove this
@use "variables" as var;

.drop-box {
  box-shadow: 0px 2px 6px #0f2b7941;
  border-radius: 5px;
  position: absolute;
  width: 100px;
  height: 9px;
  background: #fff;
  text-align: center;
  padding: 15px;
  margin: 2;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var.$font-size-small;
  color: var.$gray;
  z-index: 99;
}
