@use "variables" as var;

.hc-selected-value-box {
  border: 1px solid var.$primary-blue;
  border-radius: 20px;
  display: inline-block;
  margin: 0 15px 0 0;
  color: var.$primary-blue;
  font-family: var.$font-family-base;
  font-size: var.$font-size-small;
  padding: 5px 10px;
  margin-bottom: 10px;
  span {
    cursor: pointer;
    color: var.$primary-blue;
    opacity: 0.6;
    padding-left: 15px;
    font-size: var.$font-size-title-text;
    line-height: 14px;
  }
  span:hover {
    color: #ff7b77;
  }
}
