// These classes are used to style the
// Angular Material Toasts by overriding the Angular
// Material CSS classes
@use "../utilities/variables" as var;

// DEFAULT
.mat-snack-bar-container {
  color: var.$white;
  .mat-simple-snackbar {
    justify-content: center;
  }
}

.positive-toast {
  background: var.$secondary-green;
}

.neutral-toast {
  background: var.$primary-blue-light;
}

.negative-toast {
  background: var.$secondary-red;
}

.primary-toast {
  background: var.$primary-blue;
}

.action-toast {
  background: var.$primary-blue-light;
  .mat-simple-snackbar {
    justify-content: space-between;
  }
  .mat-simple-action-snackbar {
    color: var.$white;
  }
}
