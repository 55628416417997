@use "variables" as var;

mat-form-field[appearance="outline"].arp-mat-form-field-outline {
  .mat-form-field-wrapper {
    padding-bottom: unset;

    .mat-form-field-outline:not(.mat-form-field-outline-thick) {
      .mat-form-field-outline-start {
        min-width: 8px;
        border: 1px solid var.$light-gray;
        border-radius: 8px 0 0 8px;
        border-right-style: none;
      }

      .mat-form-field-outline-gap {
        border: 1px solid var.$light-gray;
        border-left-style: none;
        border-right-style: none;
      }

      .mat-form-field-outline-end {
        border: 1px solid var.$light-gray;
        border-radius: 0 8px 8px 0;
        border-left-style: none;
      }
    }

    .mat-form-field-outline-thick {
      .mat-form-field-outline-start {
        min-width: 8px;
        border-radius: 8px 0 0 8px;
        border-right-style: none;
      }

      .mat-form-field-outline-gap {
        border-left-style: none;
        border-right-style: none;
      }

      .mat-form-field-outline-end {
        border-radius: 0 8px 8px 0;
        border-left-style: none;
      }
    }
  }

  &.mat-form-field-should-float {
    .mat-form-field-outline:not(.mat-form-field-outline-thick) {
      .mat-form-field-outline-gap {
        border-top-style: none;
      }
    }
  }
}

.arp-input {
  .mat-form-field-subscript-wrapper {
    position: relative;
  }
}
