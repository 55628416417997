// VU-20230916: the styles in this file are
// very outdated classes that are used
// in unmaintained legacy components.
// We should get rid of these as soon as we can
@use "../utilities/variables" as var;

.hc-grid-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: var.$font-size-small;
  color: var.$gray;
  .hc-grid-item {
    padding: 10px;
    text-transform: capitalize;
    height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.item-data {
  padding: 10px;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  // text-overflow: ellipsis
}

.hc-grid {
  display: grid;
  grid-template-columns: 12% 22% 25% 22% 19%;
}

.hc-grid-data {
  font-size: var.$font-size-small;
  background: var.$white;
  color: var.$gray;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
