// A bunch of CSS classes for customizing
// Angular Material Dialogs, really extraneous
.hc-modal-container {
  width: 400px;
}

.mat-dialog-title {
  text-align: center;
}

.mat-dialog-content {
  justify-content: center;
  text-align: center;
}

.mat-dialog-actions {
  justify-content: center;
}

.error {
  color: #ff7b77;
  font-size: 12px;
  display: block;
  padding: 5px 0;
}

.mat-dialog-container {
  justify-content: center;
  overflow: auto;
  border-radius: 15px;
  box-shadow: #253767 0px 3px 30px;
}

.cdk-overlay-panel.my-dialog {
  position: relative !important;
}

.cdk-global-scrollblock {
  position: initial;
}

.two-panel-modal .mat-dialog-container {
  background: none;
  box-shadow: none;
  padding: unset;
}

.simple-mat-modal {
  mat-dialog-container {
    overflow: auto;
    border-radius: 15px;
    box-shadow: #253767 0px 3px 30px;
  }
}

.simple-mat-modal.no-padding {
  .mat-dialog-container {
    padding: 0;
  }
}

.shadowless-screen-modal .mat-dialog-container {
  box-shadow: none;
  border-radius: 10px;
}
