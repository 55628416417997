// CSS selectors used by split-contract, si-detail-standards,
// si-detail-test-results components. Definitely should remove
@use "variables" as var;

#full-table {
  font-family: var.$font-family-base;
  .hc-grid {
    display: grid;
    grid-template-columns: 45% 11% 8% 10% 10% 12% 3%;
  }
  .hc-grid-header {
    background: var.$white;
    font-size: var.$font-size-small;
    color: var.$gray;
    border-bottom: 1px solid #efefee;
    .hc-grid-item {
      padding: 10px 0px 10px 10px;
    }
  }
  .hc-grid-data {
    font-size: var.$font-size-small;
    color: var.$gray;
    .hc-grid-item {
      padding: 10px 0px 10px 10px;
    }
  }
  .title {
    display: block;
    color: #9a9a9a;
    font-size: var.$font-size-xsmall;
  }
  *,
  *::before,
  *::after {
    -webkit-box-sizing: initial;
    box-sizing: initial;
  }
}
