@use "variables" as var;

// New Layout style for responsive app
.arp-app-container {
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  mat-toolbar.arp-first-toolbar {
    padding: 0 30px;
    background-color: var.$header-bg; // $primary-color
    color: var.$header-text; // #FFF
  }

  mat-sidenav-container {
    flex: 1;
  }
}

arp-price-widget {
  margin-left: auto;
  margin-right: 1rem;
}

.arp-main-panel-container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  mat-toolbar.arp-second-toolbar {
    flex: 1 1 auto;
    max-height: 0;
    height: auto;
    opacity: 0;
    transition:
      max-height 0.25s,
      opacity 0.25s;
    background-color: var.$white;
  }

  mat-toolbar.arp-second-toolbar.expanded {
    max-height: 100%;
    opacity: 1;
    border-bottom: 1px solid var.$light-gray;
  }

  mat-toolbar.arp-second-toolbar .arp-price-display {
    display: none;
  }

  mat-toolbar.arp-second-toolbar.expanded .arp-price-display {
    display: initial;
  }

  .arp-price-display {
    height: 100%;
    width: 100%;
  }

  .arp-feature-container {
    overflow-y: scroll;
    flex: 1 1 auto;
    position: relative;
    z-index: 0;
    padding: 0 20px 80px 20px;
  }

  @media only screen and (max-width: var.$breakpoint-md) {
    mat-toolbar.arp-second-toolbar.expanded + .arp-feature-container {
      display: none;
    }
  }
}

// .sicom-wrapper {
//   position: sticky;
//   top: 0;
//   z-index: 2;
//   width: 100%;
//   padding: 36px 30px;
//   background: var.$almost-white;
//   background: linear-gradient(180deg, rgba(250, 250, 250, 1) 17%, rgba(250, 250, 250, 1) 87%);
// }

// @media only screen and (min-width: var.$breakpoint-design-xs) and (max-width: var.$breakpoint-design-md) {
//   .sicom-wrapper {
//     padding: 25px 30px;
//   }
// }

@media only screen and (max-width: var.$breakpoint-sm) {
  .home-icon,
  app-top-menu {
    display: none;
  }
}
