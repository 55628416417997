// VU-20230916: the styles in this file are
// very outdated classes that are used
// in unmaintained legacy components.
// We should get rid of these as soon as we can
@use "variables" as var;

.modal-close {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

#hc-modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(46, 50, 69, 0.7);

  &.offer-error {
    z-index: 10000;
  }

  .modal-close {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
  }
  .hc-textarea {
    margin-bottom: 0 !important;
    margin-top: 30px;
  }
  .hc-modal-container {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 3px 30px #253767;
    border-radius: 15px;
    margin-top: 5%;
    width: 400px;
    margin-left: 35%;
    padding: 50px 30px;
    text-align: center;
  }
  .modal-title {
    font-size: var.$font-size-medium;
    font-family: var.$font-family-base;
    font-weight: bold;
    margin: 20px 0;
  }
  .modal-text {
    font-size: var.$font-size-small;
    font-family: var.$font-family-base;
    white-space: pre-line;
    color: var.$dark-gray;
    margin-bottom: 0;
  }
  .button-container {
    display: flex;
    padding-top: 45px;
    text-align: right;
    justify-content: center;
  }
  /*Full Modal */
  .hc-modal-full-container {
    width: 90%;
    margin-left: 3%;
    margin-top: 1%;
  }
  // Offer Full Confirm
  .hc-grid {
    display: grid;
    grid-template-columns: 8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 8% 12%;
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    .hc-grid-item {
      padding: 13px 0;
      color: var.$gray;
      font-size: var.$font-size-small;
    }
  }
  .hc-grid-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 20px 0;
    .hc-grid-item {
      border-bottom: none;
    }
  }
  .hc-grid-data {
    padding-bottom: 20px;
  }
}
