// Custom CSS selectors for customzing various
// input, textarea elements in the application
@use "variables" as var;

.hc-input {
  width: 70px;
  border: 1px solid var.$gray;
  border-radius: 5px;
  padding: 5px;
  color: var.$black;

  &.invalid {
    box-shadow: 0px 1px 5px #ff7b7733;
    border: 1px solid #ff7b77;
  }
}

.hc-email {
  border: 1px solid var.$gray;
  border-radius: 5px;
  font-size: var.$font-size-small;
  font-family: var.$font-family-base;
  color: var.$gray;
  padding: 15px;
  width: 90%;
}

.hc-email:focus {
  border: 1px solid var.$primary-blue-light;
  border-radius: 5px;
  box-shadow: var.$primary-blue-light 0px 0px 5px;
  font-size: var.$font-size-small;
  font-family: var.$font-family-base;
  color: var.$dark-gray;
  padding: 15px;
  width: 90%;
}

.hc-input::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hc-input:hover {
  border: 1px solid var.$primary-blue-light;
  color: var.$dark-gray;
}

.hc-input:active {
  box-shadow: 0px 0px 5px var.$primary-blue-light;
  border: 1px solid var.$primary-blue-light;
  color: var.$dark-gray;
}

.input-error {
  font-size: 12px;
  color: #ff7b77;
  display: inherit;
  margin-top: 8px;
}

.hc-search {
  background-color: #e4e8f6;
  border-radius: 20px;
  padding: 10px;
  border: none;
  color: #7790d699;
  font-family: var.$font-family-base;
}

.hc-textarea {
  border: 1px solid var.$light-gray;
  border-radius: 10px;
  font-size: var.$font-size-small;
  font-family: var.$font-family-base;
  color: var.$gray;
  padding: 15px;
  margin-bottom: 30px;
}

.hc-textarea:focus {
  outline: none;
  border-color: var.$primary-blue-light;
}
