@use "../utilities/variables" as var;

#hc-button-basic {
  border: 2px solid var.$secondary-blue-light;
  border-radius: 10px;
  font-family: var.$font-family-base;
}

#hc-button-basic:hover {
  background-color: var.$secondary-blue-light;
}

#hc-button-basic:disabled {
  border: 2px solid var.$secondary-blue-light;
  color: #f6f6f6;
}

#hc-button-primary {
  background: var.$primary-blue;
  border-radius: 10px;
  color: var.$primary-button-text-color;
  font-family: var.$font-family-base;
}

#hc-button-primary:hover {
  box-shadow: 0px 1px 8px #0f2b7966;
}

#hc-button-primary:disabled {
  opacity: 0.6;
}

#hc-button-no-border {
  color: var.$primary-blue;
  border: none;
  font-size: var.$font-size-small;
  font-family: var.$font-family-base;
}

#hc-button-accept {
  background: var.$secondary-green;
  border: none;
  color: #fff;
  font-size: var.$font-size-small;
  font-family: var.$font-family-base;
  border-radius: 10px;
}

#hc-button-accept:hover {
  box-shadow: 0px 1px 8px #23792a66;
}

#hc-button-decline {
  background: var.$secondary-red;
  border: none;
  color: #fff;
  font-size: var.$font-size-small;
  font-family: var.$font-family-base;
  border-radius: 10px;
}

#hc-button-decline:hover {
  box-shadow: 0px 1px 8px #d86e6b66;
}

#hc-button-reinstate {
  background: var.$gray;
  border: none;
  color: #fff;
  font-size: var.$font-size-small;
  font-family: var.$font-family-base;
  border-radius: 10px;
}

#hc-button-reinstate:hover {
  box-shadow: 0px 1px 8px #59595966;
}

/* Modal Buttons */
// TODO: Use solid colors for all
#hc-modal-primary-button {
  background: var.$modal-blue-button-background;
  color: #fff;
  font-size: var.$font-size-small;
  border-radius: 95px;
}

#hc-modal-primary-button:hover {
  background: var.$modal-blue-button-background-hover;
}

#hc-modal-no-border-button {
  background: none;
  border-radius: 95px;
  color: var.$black;
}

#hc-modal-no-border-button:hover {
  background: var.$secondary-blue-light;
}

/* Generic Button */
button:disabled {
  opacity: 0.5;
}

.preview-button {
  color: var.$primary-blue-light;
  font-family: var.$font-family-base;
  font-size: var.$font-size-small;
  padding: 20px;
  cursor: pointer;
  img {
    padding: 0 10px;
  }
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background: var.$primary-blue;
  color: var.$primary-button-text-color;
}

.arp-button-primary {
  border-radius: 95px !important;
  background: var.$primary-blue;
  color: var.$primary-button-text-color;
}

.arp-button-secondary {
  border-radius: 95px !important;
  background: var.$primary-blue-light;
}

.arp-button-default {
  border-radius: 95px !important;
  background: var.$almost-white;
}
