@use "variables" as var;

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var.$light-gray;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var.$primary-blue-light;
}

::-webkit-scrollbar-track {
  background: var.$white-theme-background-gray;
}
