@use "variables" as var;

#hc-dropdown {
  font-family: var.$font-family-base;
  font-size: var.$font-size-small;
  padding-bottom: 15px;
  .mat-form-field-wrapper {
    padding: 0;
  }
  .hc-select-trigger {
    max-width: 250px;
    overflow: hidden;
    display: inline-block;
    height: 15px;
  }
  span.mat-form-field-label-wrapper label {
    color: var.$dark-gray;
    padding: 5px 0 0 10px;
  }
  .mat-option,
  .mat-option-text,
  .mat-option.mat-selected:not(.mat-option-disabled) {
    color: var.$black;
    font-family: var.$font-family-base;
    font-size: var.$font-size-small;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-select {
    padding: 10px 0px 10px 20px;
    border: 1px solid var.$gray;
    border-radius: 10px;
  }
  .mat-form-field-infix {
    padding: 0;
    border: none;
    max-width: 168px;
  }
  .mat-select-panel {
    background: var.$white 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #0f2b7912;
    border: 0.5px solid var.$light-gray;
  }
  .selected-value {
    display: inline;
  }
  .select-count {
    border: 1px solid var.$primary-blue;
    background: var.$primary-blue;
    color: #fff;
    border-radius: 100%;
    text-align: center;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    line-height: 15px;
    display: inline;
    font-size: 10px;
    position: absolute;
    right: 25px;
    top: 1px;
  }
  .invalid {
    box-shadow: 0px 1px 5px #ff7b7733;
    border: 1px solid #ff7b77;
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var.$dark-gray;
  font-family: var.$font-family-base;
  font-size: var.$font-size-small;
  background: var.$secondary-blue-light;
}

.mat-option.mat-active {
  background: var.$secondary-blue-light;
}

.mat-select-panel {
  margin-top: 40px;
}

.mat-select-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.arp-mat-select-trigger-count {
  border: 1px solid var.$primary-blue;
  background: var.$primary-blue;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 15px;
  position: absolute;
  right: 1.5rem;
  transform: translateY(-25%);
}

// Customized mat-select for price-configurator
.premium-category-select {
  #hc-dropdown {
    width: 100%;
    padding-bottom: unset;

    .mat-select {
      height: 41px;
      padding: 10px 0px 10px 10px;
      background-color: var.$white;
    }
    .mat-select.price-simulator {
      background-color: var.$secondary-blue-light;
    }

    .mat-form-field-infix {
      max-width: unset;
    }

    .mat-select-arrow {
      margin-right: 10px;
    }
  }
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var.$primary-blue;
}
