@use "variables" as var;

.mat-paginator[appPaginator] {
  width: 100%;
  background-color: transparent;

  .mat-paginator-container {
    font-size: var.$font-size-small;
  }

  .mat-paginator-range-label {
    margin: unset;
  }

  // TOTAL LABELS
  .custom-paginator-total-label-container {
    display: flex;
  }
  .custom-paginator-total-label-value {
    margin-left: 10px;
    color: var.$black;
  }
  // DROPDOWN SELECTION
  .mat-form-field-underline {
    display: none;
  }
  .mat-paginator-page-size .mat-select-trigger {
    font-size: var.$font-size-small;
  }

  // NAVIATION BUTTONS
  .mat-custom-page {
    border: none;
    background-color: transparent;
  }

  .custom-paginator-counter {
    order: 2;
  }

  .custom-paginator-page-enabled {
    color: var.$dark-gray;
    cursor: pointer;
  }
  .custom-paginator-page-disabled:not(.custom-paginator-current-page) {
    color: var.$black;
    opacity: 0.38 !important;
    pointer-events: none;
  }
  .custom-paginator-current-page {
    color: var.$white;
    background-color: var.$primary-blue;
    border-radius: 20%;
  }
  .mat-select-arrow {
    color: var.$primary-blue;
  }

  .mat-paginator-navigation-previous.custom-paginator-arrow-enabled {
    color: var.$primary-blue;
  }
  .mat-paginator-navigation-next.custom-paginator-arrow-enabled {
    color: var.$primary-blue;
  }
}

.offer-bid-send-modal .mat-paginator-container {
  .mat-paginator-page-size-label,
  .mat-paginator-page-size-value {
    display: none;
  }
}
