@use "variables" as var;

#hc-tab {
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var.$primary-blue;
  }

  .mat-tab-label {
    text-align: left;
    padding: 0 10px;
  }

  .mat-ink-bar {
    width: 80px !important;
    margin-left: 20px;
  }

  .mat-tab-body.mat-tab-body-active {
    overflow-y: hidden;
  }
}

.arp-mat-tab-container {
  .mat-tab-label-content {
    font-size: var.$font-size-title-text;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var.$primary-blue;
}
