// This has to be an id selector in order to
// override the specificity of the Angular Material
// styles
#hc-accordion {
  box-shadow: none;

  .mat-expansion-panel-body {
    padding: 0;
  }
}
