// For external resources, like fonts and bootstrap grids
@use "./app/styles/external.scss";
// For global variables
@use "./app/styles/utilities/variables" as var;
// For any utility selectors
@use "./app/styles/utilities";
// For ARP specific customization
@use "./app/styles/components/all";
// NOTE: Angular Material prebuilt legacy theme is already included
// in the styles array of angular.json.
// This is only done so that we can use Angular Material
// Mixins for customizing the typography of Angular Material
// Components
@use "@angular/material" as mat;

// Default styles
:root {
  --headerBackground: #1446ce; // #000;
  --headerText: #fff;
  --primaryColor: #1446ce;
  --primaryBtnTextColor: #fff;

  --secondaryColor: #95ace9;
  --tableSecondaryBackground: #dfe6f8;

  --svgFillColor: var(--tableSecondaryBackground);
  --accentColor: var(var(--primaryColor));
}

// Define a typography config using the define-legacy-typography-config
// mixin from Angular Material
$typography-config: mat.define-legacy-typography-config(
  $font-family: var.$font-family-base,
);
// Generate only the typography specific CSS selectors for Angular Material
// Components.
@include mat.all-legacy-component-typographies($typography-config);

// The following commented-out code should only be used if you want
// to totally customize the theming of Angular Material Components,
// which also means you MUST remove the Angular Material prebuilt
// legacy theme from the styles array of angular.json.

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// @include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $arp-primary: mat.define-palette(mat.$indigo-palette);
// $arp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $arp-theme: mat.define-light-theme((
//   color: (
//     primary: $arp-primary,
//     accent: $arp-accent,
//     warn: $arp-warn,
//   ),
//   typography: mat.define-legacy-typography-config($font-family: var.$font-family-base)
// ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-legacy-component-themes($arp-theme);

html,
body {
  box-sizing: border-box;
  height: 100%;
}

body {
  margin: 0;
  font-family: var.$font-family-base;
  font-size: var.$font-size-base;
}

// @VuOnCss
// Why do I have to see this again here?
// Didn't we already create these classes in utilities.scss?
// I bet 1 slice of pizza that this class will never be reused
// again
.align-right {
  text-align: right;
}

// @VuOnCss
// AHHHH, JUST ANOTHER SEMANTICALLY NAMED CSS CLASS
// THAT ACT LIKE A UTILITY CLASS
.vertical-divider {
  border-left: 1.5px solid var.$gray;
  height: 30px;
}

// @VuOnCss
// AHHHH, JUST ANOTHER SEMANTICALLY NAMED CSS CLASS
// THAT ACT LIKE A UTILITY CLASS
// Btw I wonder how many times I have seen 'cursor: pointer'
// SET ELEMENT TO POINTER
.clickable-link {
  cursor: pointer;
}

// TRUNCATE TEXT
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// @VuOnCss
// HOW DARE YOU ASSUME THAT a textarea WILL NEVER BE RESIZED?
// THE AUDACITY!
// TEXT AREA RESIZE FIXED
textarea {
  resize: none;
}

// Fix #1 for material dialog shifting html and body up when popping up
.cdk-global-scrollblock {
  position: initial;
  width: initial;
  overflow: hidden;
}
// @VuOnCss
// WTF?
.icon-x-small {
  width: 13px !important;
  height: 13px !important;
  font-size: 13px;
  stroke-width: 1;
}
.icon-small {
  width: 16px !important;
  height: 16px !important;
  font-size: 16px;
  stroke-width: 1;
}
.icon-medium {
  width: 24px !important;
  height: 24px !important;
  font-size: 24px;
  stroke-width: 1;
}
.icon-large {
  width: 32px !important;
  height: 32px !important;
  font-size: 32px;
  stroke-width: 1;
}
.icon-x-large {
  width: 64px !important;
  height: 64px !important;
  font-size: 64px;
  stroke-width: 1;
}
.icon-xx-large {
  width: 128px !important;
  height: 128px !important;
  font-size: 128px;
  stroke-width: 1;
}
.icon-xxx-large {
  width: 256px !important;
  height: 256px !important;
  font-size: 256px;
  stroke-width: 1;
}
.icon-xxxx-large {
  width: 450px !important;
  height: 300px !important;
  font-size: 300px;
  stroke-width: 1;
}

.iframe-container {
  border: none;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  padding-top: 0;
}
