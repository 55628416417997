@use "variables" as var;

.arp-mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  border: 1px solid var.$primary-blue;
  span.mat-button-toggle-label-content {
    line-height: 2em;
  }
  mat-button-toggle {
    height: 30px;
    min-width: 80px;
  }
  .mat-button-toggle-checked {
    background-color: var.$primary-blue;
    color: var.$white;
  }
}
