@use "variables" as var;

// @VuOnCss do you think anyone in your team
// would know and use this CSS class to align-center their
// stuff?
.align-center {
  display: flex;
  align-items: center;
}

.widget-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.expanded-display {
  display: flex;
  align-items: center;
}

.small-font {
  font-size: 0.75rem;
  line-height: 0.75rem;
}

// @VuOnCss good try, but
// what if I don't like $primary-blue.
// Btw what the hell is $primary-blue? How do I know
// it's actually blue?
.text-blue {
  color: var.$primary-blue;
}

.text-accent {
  color: var.$accent-color;
}

// @VuOnCss I want to 'emphasize' my
// text, but what if I want to emphasize to
// different font-weight? Guess I'll need to
// write another Css class hehe
.emphasized {
  font-weight: 800;
}

// @VuOnCss Wth is normal-font? Am I
// 'abnormal' if I don't use this class?
.normal-font {
  font-size: 1rem;
  line-height: 1rem;
}

// @VuOnCss boooo, my designers tell me
// this is not large enough
.large-font {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.extra-large-font {
  font-size: 2rem;
  line-height: 2rem;
}

.not-display {
  display: none;
}

mat-icon.not-display {
  display: none;
}

// @VuOnCss, in China 'positive' is
// red color bro
.positive {
  color: var.$secondary-green;
}

.negative {
  color: var.$secondary-red;
}

// @VuOnCss why is flat black?
// what if I want to drink flat white?
.flat {
  color: var.$black;
}

.delayed-color {
  color: var.$primary-blue-light;
}

.stale-color {
  color: var.$warning;
}

// @VuOnCss, again and again,
// you are full of assumptions
.field-name {
  font-size: 0.75rem;
  line-height: 18px;
  font-weight: 600;
}

.field-value {
  font-size: 0.75rem;
  line-height: 18px;
  font-weight: 800;
}

.blurred {
  filter: blur(0.3125rem);
}

.local-loader {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.chart-container {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 0.5rem;
}

.range-picker {
  flex: 0 0 auto;
}

.chart-area {
  flex: 1 1 auto;
}

.chart {
  position: relative;
  width: 100%;
  min-height: 250px;
}
